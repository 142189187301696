import React, { Component, Fragment } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import CreatePageSnippetForm from '../components/CreatePageSnippetForm';
import axios from 'axios';
import { Panel, Col, Row, Button, FormControl, Form } from 'react-bootstrap';
import PlainPanel from '../components/PlainPanel';
import config from '../config';
import qs from 'qs';
import { Link } from 'react-router-dom';

import './CreatePageSnippet.css';

class CreatePageSnippet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userIsAuthorized: props.userIsAuthorized,
            organisation: { planType: ""}
        };
    }

    componentDidMount() {
        this.loadPageFromServer();
    }

    loadPageFromServer() {
        const { uuid, pageUUID } = this.props.match.params;

        var self = this;
        axios
            .get(`${config.baseURL}/pages/${pageUUID}`, {})
            .then(function(response) {
                self.setState({ page_name: response.data.page.name });
                self.loadOrganisationInformation();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadOrganisationInformation() {
        var self = this;

        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation_name: response.data.organisation.name,
                    organisation: response.data.organisation
                });

                const parsed = qs.parse(
                    self.props.location.search.substring(1)
                );
                const rel = parsed.rel;
                if (rel === 'player') {
                    self.loadPlayerFromServer();
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadPlayerFromServer() {
        var self = this;
        const { playerUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/players/${playerUUID}`, {})
            .then(function(response) {
                self.setState({
                    player_name: response.data.player.name
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'CreatePageSnippet.title'
        )}`;

        const { uuid, playerUUID, pageUUID } = this.props.match.params;

        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;

        return (
            <div className="CreatePageSnippet">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <div className="row">
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('CreatePageSnippet.title')}

                                    {rel === 'player' ? (
                                        <Fragment>
                                            {' - '}
                                            {this.state.page_name}{' '}
                                            <small>
                                                {this.state.player_name}{' '}
                                                <small
                                                    style={{
                                                        whiteSpace: 'nowrap'
                                                    }}
                                                >
                                                    {
                                                        this.state
                                                            .organisation_name
                                                    }
                                                </small>
                                            </small>
                                        </Fragment>
                                    ) : (
                                        <small>
                                            {this.state.organisation_name}
                                        </small>
                                    )}
                                </h1>
                                <ol className="breadcrumb">
                                    {rel === 'player' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players`}
                                                >
                                                    {t(
                                                        'SideNavigation.players'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}`}
                                                >
                                                    {t(
                                                        'OrganisationPlayer.title'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages`}
                                                >
                                                    {t('SideNavigation.pages')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}?rel=player`}
                                                >
                                                    {t('Page.title')}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-plus" />{' '}
                                                {t('CreatePageSnippet.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                    {rel === 'page' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages`}
                                                >
                                                    {t(
                                                        'SideNavigation.globalpages'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages/${pageUUID}?rel=page`}
                                                >
                                                    {t('Page.title')}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-edit" />{' '}
                                                {t('Page.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                </ol>

                                <PlainPanel>
                                    <CreatePageSnippetForm
                                        userIsAuthorized={this.state.userIsAuthorized}
                                        uuid={uuid}
                                        user={this.props.user}
                                        organisation={this.state.organisation}
                                        pageUUID={pageUUID}
                                    />
                                </PlainPanel>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(CreatePageSnippet);
