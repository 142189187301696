import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import NavigationSidebar from './NavigationSidebar';
import { Nav, Navbar, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import './Navigation.css';
import config from '../config';

import axios from 'axios';
import logo from '../menu_logo.png';
import Select from 'react-select';

axios.defaults.withCredentials = true;

class Navigation extends Component {
    constructor(props) {
        super(props);
    }

    handleLogout = event => {
        var self = this;
        axios
            .get(`${config.baseURL}/auth/logout`)
            .then(function(response) {
                self.props.props.userHasAuthenticated(false, {
                    isAuthenticated: false,
                    isEngineer: false
                });
                self.props.props.history.push('/login');
            })
            .catch(function(error) {
                console.log(error);
            });
    };

    handleUserSettings = event => {
        this.props.props.history.push('/usersettings');
    };

    render() {
        const { t } = this.props;

        const childProps = {
            currentOrganisation: this.props.props.currentOrganisation,
            user: this.props.props.user,
            userHasAuthenticated: this.props.props.userHasAuthenticated,
            userIsAuthorized: this.props.props.userIsAuthorized,
            changeOrganisation: this.props.props.changeOrganisation,
            match: this.props.props.match
        };

        return (
            <div className="Navigation">
                <Navbar
                    fixedTop
                    fluid
                    collapseOnSelect
                    id="navbar-ex1-collapse"
                >
                    <Navbar.Header>
                        <Navbar.Brand>
                            <Link
                                to={`/organisations/${
                                    this.props.props.currentOrganisation.uuid
                                }`}
                            >
                                <img
                                    src={logo}
                                    alt="logo"
                                    style={{ width: 104, height: 30 }}
                                />
                            </Link>
                        </Navbar.Brand>

                        <Navbar.Toggle />
                    </Navbar.Header>
                    <Navbar.Collapse>
                        <Nav pullLeft>
                            <NavDropdown
                                eventKey={4}
                                title={
                                    <span>
                                        <i className="fa fa-fw fa-briefcase" />{' '}
                                        {
                                            this.props.props.currentOrganisation
                                                .name
                                        }
                                    </span>
                                }
                                id="basic-nav-dropdown"
                            >
                                {this.props.props.user.organisations.map(
                                    (organisation, i) => {
                                        return (
                                            <LinkContainer
                                                to={`/organisations/${
                                                    organisation.uuid
                                                }`}
                                            >
                                                <MenuItem
                                                    onClick={() => {
                                                        this.props.props.history.push(
                                                            `/organisations/${
                                                                organisation.uuid
                                                            }`
                                                        );
                                                        childProps.changeOrganisation(
                                                            organisation
                                                        );
                                                    }}
                                                >
                                                    {organisation.name}
                                                </MenuItem>
                                            </LinkContainer>
                                        );
                                    }
                                )}
                            </NavDropdown>
                        </Nav>
                        <Nav pullRight>
                            {childProps.userIsAuthorized(childProps.user, [
                                'staff_super_admin',
                                'staff_admin'
                            ]) ? (
                                <NavDropdown
                                    eventKey={4}
                                    title={
                                        <span>
                                            <i className="fa fa-globe fa-fw" />{' '}
                                            {t('Navigation.global')}
                                        </span>
                                    }
                                    id="basic-nav-dropdown"
                                >
                                    <LinkContainer to="/organisations">
                                        <MenuItem>
                                            <i className="fa fa-fw fa-briefcase" />{' '}
                                            {t('Navigation.organisations')}
                                        </MenuItem>
                                    </LinkContainer>
                                    <LinkContainer to="/users">
                                        <MenuItem>
                                            <i className="fas fa-fw fa-users" />{' '}
                                            {t('Navigation.users')}
                                        </MenuItem>
                                    </LinkContainer>
                                    <LinkContainer to="/players">
                                        <MenuItem>
                                            <i className="fa fa-fw fa-desktop" />{' '}
                                            {t('Navigation.players')}
                                        </MenuItem>
                                    </LinkContainer>
                                    <LinkContainer to="/facts">
                                        <MenuItem>
                                            <i className="fa fa-fw fa-info-circle" />{' '}
                                            {t('Navigation.facts')}
                                        </MenuItem>
                                    </LinkContainer>
                                    <LinkContainer to="/otd">
                                        <MenuItem>
                                            <i className="far fa-fw fa-calendar-alt" />{' '}
                                            {t('Navigation.onthisday')}
                                        </MenuItem>
                                    </LinkContainer>
                                    <LinkContainer to="/qanda">
                                        <MenuItem>
                                            <i className="fa fa-fw fa-question" />{' '}
                                            {t('Navigation.qanda')}
                                        </MenuItem>
                                    </LinkContainer>
                                    <LinkContainer to="/templatelibraries">
                                        <MenuItem>
                                            <i className="fas fa-images" />{' '}
                                            {t('Navigation.templatelibraries')}
                                        </MenuItem>
                                    </LinkContainer>
                                </NavDropdown>
                            ) : null}
                            <NavDropdown
                                eventKey={3}
                                title={
                                    <span>
                                        <i className="fas fa-user fa-fw" />{' '}
                                        {this.props.props.user.firstName}{' '}
                                        {this.props.props.user.lastName}
                                    </span>
                                }
                                id="basic-nav-dropdown"
                            >
                                {/*<MenuItem eventKey={3.2}>
                                    <i className="fa fa-fw fa-gear" /> {t('Navigation.settings')}
                                </MenuItem>
                                <MenuItem divider />*/}
                                 { childProps.userIsAuthorized(childProps.user, ['staff_super_admin']) ? 
                                 (<Fragment>
                                    <MenuItem onClick={this.handleUserSettings}>
                                        <i className="fa fa-fw fa-cog" />{' '}
                                        {t('Navigation.settings')}
                                    </MenuItem>
                                 </Fragment>) : (null)}
                                <MenuItem onClick={this.handleLogout}>
                                    <i className="fa fa-fw fa-power-off" />{' '}
                                    {t('Navigation.logout')}
                                </MenuItem>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                    <NavigationSidebar childProps={childProps} />
                </Navbar>
            </div>
        );
    }
}

export default translate('translations')(Navigation);
