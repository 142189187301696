import React, { Component } from 'react';
import './Organisation.css';
import Navigation from '../components/Navigation';
import DashboardTile from '../components/DashboardTile';
import { Col, Row } from 'react-bootstrap';

import config from '../config';
import axios from 'axios';

import { translate } from 'react-i18next';

class Organisation extends Component {
    constructor(props) {
        super(props);
        const childProps = {
            currentOrganisation: this.props.currentOrganisation,
            user: this.props.user,
            userHasAuthenticated: this.props.userHasAuthenticated,
            userIsAuthorized: this.props.userIsAuthorized
        };

        this.state = {
            uuid: this.props.match.params.uuid,
            user: this.props.user,
            currentOrganisation: this.props.currentOrganisation,
            userIsAuthorized: childProps.userIsAuthorized,
            user: childProps.user,
            organisation: {},
            statistics: {
                users: 0,
                players: 0,
                attributes: 0,
                snippets: 0,
                medialibraries: 0,
                pages: 0,
                textlibraries: 0,
                emailattributes: 0
            }
        };
    }

    componentDidMount() {
        this.loadOrganisationInformation();
        this.loadStatisticsFromServer();
    }

    componentDidUpdate(prevProps) {
        // will be true
        const locationChanged = this.props.location !== prevProps.location;

        if (locationChanged) {
            this.loadOrganisationInformation();
            this.loadStatisticsFromServer();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.uuid !== this.props.match.params.uuid) {
            this.setState({
                uuid: nextProps.match.params.uuid
            });
        }
    }

    loadOrganisationInformation() {
        var self = this;

        axios
            .get(`${config.baseURL}/organisations/${this.state.uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation_name: response.data.organisation.name,
                    organisation: response.data.organisation
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadStatisticsFromServer() {
        var self = this;

        axios
            .get(
                `${config.baseURL}/organisations/${this.state.uuid}/statistics`,
                {}
            )
            .then(function(response) {
                console.log('setting state');
                self.setState({
                    statistics: response.data.statistics
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'Dashboard.title'
        )}`;

        const allSnippetsAuthorised = this.props.userIsAuthorized(this.props.user, ['staff_super_admin']) || this.state.organisation.planType === "PREMIUM";

        return (
            <div className="Organisation">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('Dashboard.title')}{' '}
                                    <small>
                                        {this.state.organisation_name}
                                    </small>
                                    <a
                                        id="settingsButton"
                                        type="submit"
                                        className="btn btn-primary"
                                        href={`/organisations/${this.state.organisation.uuid}/settings`}
                                    >
                                        <em className="fa fa-cog fa-fw" />{' '}
                                        {t('Organisation.settings')}
                                    </a>
                                </h1>
                                <ol className="breadcrumb">
                                    <li className="active">
                                        <i className="fa fa-tachometer-alt" />{' '}
                                        {t('Dashboard.title')}
                                    </li>
                                </ol>
                            </Col>
                        </Row>
                        <Row>
                            <DashboardTile
                                title={t('SideNavigation.players')}
                                count={this.state.statistics.players}
                                buttonText={t('View')}
                                icon="fa fa-desktop fa-5x"
                                colour="green"
                                link={`/organisations/${
                                    this.state.organisation.uuid
                                }/players`}
                            />
                            {this.state.userIsAuthorized(this.state.user, [
                                'staff_super_admin',
                                'staff_admin',
                                'organisation_admin'
                            ]) ? (
                                <DashboardTile
                                    title={t('SideNavigation.users')}
                                    count={this.state.statistics.users}
                                    buttonText={t('View')}
                                    icon="fas fa-users fa-5x"
                                    colour="primary"
                                    link={`/organisations/${
                                        this.state.organisation.uuid
                                    }/users`}
                                />
                            ) : null}
                            {this.state.userIsAuthorized(this.state.user, [
                                'staff_super_admin',
                                'staff_admin',
                                'organisation_admin',
                                'organisation_user'
                            ]) ? (
                                <DashboardTile
                                    title={t('SideNavigation.attributes')}
                                    count={this.state.statistics.attributes}
                                    buttonText={t('View')}
                                    icon="fa fa-tags fa-5x"
                                    colour="yellow"
                                    link={`/organisations/${
                                        this.state.organisation.uuid
                                    }/attributes`}
                                />
                            ) : null}
                            <DashboardTile
                                title={t('SideNavigation.globalpages')}
                                count={this.state.statistics.pages}
                                buttonText={t('View')}
                                icon="fa fa-file fa-5x"
                                colour="red"
                                link={`/organisations/${
                                    this.state.organisation.uuid
                                }/pages`}
                            />
                        </Row>
                        <Row>
                            <DashboardTile
                                title={t('SideNavigation.globalsnippets')}
                                count={this.state.statistics.snippets}
                                buttonText={t('View')}
                                icon="fa fa-puzzle-piece fa-5x"
                                colour="turquoise"
                                link={`/organisations/${
                                    this.state.organisation.uuid
                                }/snippets`}
                            />
                            <DashboardTile
                                title={t('SideNavigation.globalmedialibraries')}
                                count={this.state.statistics.medialibraries}
                                buttonText={t('View')}
                                icon="fas fa-images fa-5x"
                                colour="bergundy"
                                link={`/organisations/${
                                    this.state.organisation.uuid
                                }/medialibraries`}
                            />

                            {allSnippetsAuthorised ? (
                             <DashboardTile
                                title={t('SideNavigation.globaltextlibraries')}
                                count={this.state.statistics.textlibraries}
                                buttonText={t('View')}
                                icon="fas fa-font fa-5x"
                                colour="purple"
                                link={`/organisations/${
                                    this.state.organisation.uuid
                                }/textlibraries`}
                            />) : null }

                             {this.state.userIsAuthorized(this.state.user, [
                                'staff_super_admin',
                                'staff_admin',
                                'organisation_admin'
                            ]) ? (
                                <DashboardTile
                                    title={t('SideNavigation.emailattributes')}
                                    count={this.state.statistics.emailattributes}
                                    buttonText={t('View')}
                                    icon="fa fa-tags fa-5x"
                                    colour="yellow"
                                    link={`/organisations/${
                                        this.state.organisation.uuid
                                    }/emailattributes`}
                                />
                            ) : null}
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(Organisation);
